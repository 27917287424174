import type { AirtableFileRecord } from "../AirtableConnector";

interface CreateSectionIdProps {
  blockName: string;
  index: number;
  customSectionId?: string;
}

export const createSectionId = ({
  blockName,
  index,
  customSectionId,
}: CreateSectionIdProps) => {
  if (customSectionId) return customSectionId;
  if (index === 0) return blockName;
  return `${blockName}-${index + 1}`;
};

export const debounce = (func: () => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(), wait);
  };
};

export async function getFileType(
  url: string,
): Promise<"video" | "image" | "unknown"> {
  try {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");

    if (contentType?.startsWith("image/")) {
      return "image";
    } else if (contentType?.startsWith("video/")) {
      return "video";
    } else {
      return "unknown";
    }
  } catch (error) {
    console.error("Error fetching URL:", error);
    return "unknown";
  }
}

export function hexToRgba(hex: string, alpha: number): string {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the hexadecimal string
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

/**
 * Capitalizes the first letter of each word in a string
 * @param str - The string to capitalize
 * @returns The capitalized string
 */
export function capitalizeString(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

/**
 * Finds if the input AirtableFileRecord field is an svg image or not
 * @param {AirtableFileRecord} file - The AirtableFileRecord to check
 * @returns {boolean} - true if the file is an svg image, false otherwise
 */
export function isSvgImage(file?: AirtableFileRecord): boolean {
  if (!file) return false;
  return file.type === "image/svg+xml";
}

export function normalizeUrl(url: string): string {
  // Remove the trailing slash if the URL ends with one
  if (!url) return url;
  return url.endsWith("/") ? url.slice(0, -1) : url;
}

/**
 * Trims a string and removes brackets from it
 * @param {string} str - The string to trim and remove brackets from
 * @returns {string} The trimmed string with brackets removed
 */
export function trimAndRemoveBrackets(str: unknown): string {
  let stringToParse = "";
  if (!str) return "";
  if (typeof str !== "string") stringToParse = JSON.stringify(str);
  else stringToParse = str;

  return stringToParse.trim().replace(/<|>/g, "");
}

/**
 * Trims a string and removes escape symbols from it
 * @param {string} str - The string to be parsed
 * @returns {string} The trimmed string with escapes symbols removed
 */
export function parseEscapedUrl(str: unknown): string {
  if (!str) return "";
  let stringToParse = "";
  if (typeof str !== "string") {
    stringToParse = JSON.stringify(str);
  } else {
    stringToParse = str;
  }

  return stringToParse
    .trim()
    .replace(/\\n/g, "") // Remove escaped newlines
    .replace(/\\\\/g, "\\") // Convert double backslashes to single backslash
    .replace(/\\_/g, "_"); // Remove backslash before underscore
}
